// contexts/AppContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { deleteCookie, getCookie } from 'cookies-next';
import * as Sentry from '@sentry/nextjs';
import { getSession, signOut, useSession } from 'next-auth/client';
import { LangContext } from '../src/ConnectedIntlProvider';
import { useRouter } from 'next/router';

interface AuthData {
    userAvatar: string;
    userEmail: string;
    userName: string;
    userUUID: string | null;
    userPhone: string;
    userTeams: any[]; // uprav dle struktury týmů
    companyUUID: string | null;
    companyName: string | null;
    companyCurrentPlan: string | null;
    companyCurrentSeats: string | null;
    companyCurrentPlanRecurringDate: string | null;
    companyCurrentPlanRecurringPrice: string | null;
    companyCurrentPeriod: string | null;
    companyCurrentPlanSubscriptionEnd: string | null;
    apiVersion: string;
    teamAvatar: string;
    userLanguage : string;
    userRole: string;
    teamCredits: number;
    appExternal: boolean;
    teamPlan: string;
    needToInitTimezone: boolean;
    needToInitCountry: boolean;
    needToInitLang: boolean;
    userDeveloper: boolean;
}

interface AppContextProps {
    authData: AuthData;
    setAuthData: React.Dispatch<React.SetStateAction<AuthData>>;
    refreshAuthInfo: (sess?: any) => void;
    loadTeamInfoAPI: () => void;
    loadCurrencies: () => void;
    loadLanguages: () => void;
    setCountryFunction: (country: string) => void;
    setTimezoneFunction: (timezone: string) => void;
    setCurrencyFunction: (currency: string) => void;
    setLanguageFunction: (lang: string) => void;
    allCurrencies: any;
    allLanguages: any;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

interface AppProviderProps {
    children: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
    // Základní session z NextAuth (může být cachovaná)
    const [session, loading] = useSession();
    // Vlastní lokální stav pro aktuální session, získaný manuálně
    const [currentSession, setCurrentSession] = useState<any>(null);

    // Efektivní session – pokud máme currentSession, použijeme ji, jinak fallback na session
    const effectiveSession = currentSession || session;

    // --- Stav pro auth data
    const [authData, setAuthData] = useState<AuthData>({
        userAvatar: "",
        userEmail: "",
        userName: "",
        userUUID: null,
        userPhone: "",
        userTeams: [],
        userLanguage : "en",
        companyUUID: null,
        companyName: null,
        companyCurrentPlan: null,
        companyCurrentSeats: null,
        companyCurrentPlanRecurringDate: null,
        companyCurrentPlanRecurringPrice: null,
        companyCurrentPeriod: null,
        companyCurrentPlanSubscriptionEnd: null,
        apiVersion: "0.0.0",
        teamAvatar: "",
        userRole: "user",
        teamCredits: 0,
        appExternal: false,
        teamPlan: "basic",
        needToInitTimezone: false,
        needToInitCountry: false,
        needToInitLang: false,
        userDeveloper: false
    });

    // Další stavy
    const [allCurrencies, setAllCurrencies] = useState<any>({});
    const [allLanguages, setAllLanguages] = useState<any>({});
    const [preferredLanguage, setPreferredLanguage] = useState("0f6e27c3-c2cd-42a4-a18c-c9ed4fd5e4f3");

    const getBrowserLocale = () => {
        try {
            return window.navigator.language.split('-')[0].toLowerCase();
        } catch {
            return 'en';
        }
    };

    // --- Funkce pro načtení informací o týmu
    const loadTeamInfoAPI = () => {
        const tokenMain = getCookie('userTeam') || effectiveSession?.accessToken || null;
        if (tokenMain) {
            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'teams/current', {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + getCookie('userTeam'),
                },
            })
                .then(response => response.json())
                .then(data => {
                    if (data.message !== undefined) {
                        Sentry.captureException('teams/current: ' + data.message + ' : ' + getCookie('userMail'));
                    } else {
                        console.log("LOADING TEAM INFO", data);

                        if(data.subscription){

                            console.log("SUBSITIPSDIO PAYMENT",data);

                            setAuthData(prev => ({ ...prev, companyCurrentPlan: data.subscription.plan,
                                companyCurrentSeats: data.subscription.seats }));

                            if(data.subscription["upcoming_payment"]){
                                if(data.subscription["upcoming_payment"].active){
                                    setAuthData(prev => ({ ...prev, companyCurrentPlanRecurringDate: new Date(data.subscription["upcoming_payment"].date).toLocaleString(),
                                        companyCurrentPlanRecurringPrice:new Date(data.subscription["upcoming_payment"].date).toLocaleString(),
                                        companyCurrentPeriod: data.subscription.period }));

                                } else {
                                    setAuthData(prev => ({ ...prev, companyCurrentPlanSubscriptionEnd: new Date(data.subscription["upcoming_payment"].date_end).toLocaleString() }));

                                }

                            }
                            setAuthData(prev => ({ ...prev, companyName: data.name }));


                        }

                    }
                });
        }
    };
    const router = useRouter();
    // --- Funkce pro načtení a refresh uživatelských informací
    const refreshAuthInfo = (sess?: any) => {
        const tokenMain = getCookie('userTeam') || (sess?.accessToken || effectiveSession?.accessToken) || null;
        if (tokenMain) {
            console.log("refreshAuthInfo called");
            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'auth/info', {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + tokenMain,
                },
            })
                .then(response => response.json())
                .then(data => {
                    console.log("LOADING AUTH INFO", data);
                    if (data.message !== undefined) {

                        Sentry.captureException('auth/info: ' + data.message + ' : ' + getCookie('userMail'));
                        deleteCookie('userTeam');
                        deleteCookie('userTeamUUID');
                        deleteCookie('usrA');

                        signOut({ redirect: false }).then(() => {
                            router.push('/'); // Redirect to the dashboard page after signing out
                            window.location.reload();
                        });

                    } else {
                        setLanguage(data.user.preferred_language ? data.user.preferred_language.code : "en");
                        setAuthData(prev => ({
                            ...prev,
                            userAvatar: data.user.avatar,
                            userEmail: data.user.email,
                            userName: data.user.full_name,
                            userUUID: data.user.uuid,
                            userPhone: data.user.phone,
                            userTeams: data.teams,
                            userLanguage : data.user.preferred_language ? data.user.preferred_language.code : "en",
                            companyUUID: data.current_team,
                            apiVersion: data.api_version,
                            userDeveloper: data.user.developer,
                        }));
                        Object.keys(data.teams).forEach(key => {
                            if (getCookie('userTeamUUID') === data.teams[key]['uuid']) {
                                setAuthData(prev => ({
                                    ...prev,
                                    teamAvatar: data.teams[key]['logo'],
                                    userRole: data.teams[key]['logo'], // ověř, zda je to opravdu logo, nebo máš v úmyslu nastavit roli
                                }));
                                if (data.teams[key]['subscription'] !== undefined && data.teams[key]['role'] !== "external") {
                                    if (data.teams[key]['credits']) {
                                        setAuthData(prev => ({
                                            ...prev,
                                            teamCredits: data.teams[key]['credits']
                                        }));
                                    }
                                    setAuthData(prev => ({
                                        ...prev,
                                        teamPlan: data.teams[key]['subscription']['plan']
                                    }));
                                }
                                if (data.teams[key]['role'] === "external") {
                                    setAuthData(prev => ({
                                        ...prev,
                                        appExternal: true
                                    }));
                                }
                            }
                        });
                        // Nastavení jazyka, země a časového pásma
                        if (data.user && data.user.preferred_language === null) {
                            setAuthData(prev => ({ ...prev, needToInitLang: true }));
                        }
                        if (data.user && data.user.country === null) {
                            setAuthData(prev => ({ ...prev, needToInitCountry: true }));
                        }
                        if (data.user && data.user.timezone === null) {
                            setAuthData(prev => ({ ...prev, needToInitTimezone: true }));
                        }
                    }
                });
        }
    };

    // --- Funkce pro načtení měn
    const loadCurrencies = () => {
        if (effectiveSession?.accessToken) {
            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + "options/team", {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + effectiveSession.accessToken,
                },
            })
                .then(response => response.json())
                .then(data => {
                    if (data.message !== undefined) {
                        Sentry.captureException("options/team: " + data.message + " : " + getCookie("userMail"));
                    }
                    if (data.currencies) {
                        setAllCurrencies(data.currencies);
                    }
                });
        }
    };

    // --- Funkce pro načtení jazyků
    const loadLanguages = () => {

        if (effectiveSession?.accessToken) {
            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + "options/user", {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + effectiveSession.accessToken,
                },
            })
                .then(response => response.json())
                .then(data => {
                    console.log("loadLanguages", data);
                    if (data.message !== undefined) {
                        Sentry.captureException("options/user: " + data.message + " : " + getCookie("userMail"));
                    }
                    if (data.languages) {
                        setAllLanguages(data.languages);
                    }
                });
        }
    };
    const { language, setLanguage } = useContext(LangContext);
    // --- Funkce pro update země, časového pásma, měny a jazyka
    const setCountryFunction = (country: string) => {
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + "user/current", {
            method: 'PUT',
            body: JSON.stringify({ "country": country }),
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer " + getCookie('userTeam'),
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.message !== undefined) {
                    Sentry.captureException("user/current country: " + data.message + " : " + getCookie("userMail"));
                }
            });
    };

    const setTimezoneFunction = (timezone: string) => {
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + "user/current", {
            method: 'PUT',
            body: JSON.stringify({ "timezone": timezone }),
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer " + getCookie('userTeam'),
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.message !== undefined) {
                    Sentry.captureException("user/current timezone: " + data.message + " : " + getCookie("userMail"));
                }
            });
    };

    const setCurrencyFunction = (currency: string) => {
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + "teams/current", {
            method: 'PUT',
            body: JSON.stringify({ "preferred_currency": currency }),
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer " + getCookie('userTeam'),
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.message !== undefined) {
                    Sentry.captureException("teams/current: " + data.message + " : " + getCookie("userMail"));
                }
            });
    };

    const setLanguageFunction = (lang: string) => {
        console.log("set Language function", lang);
        if (effectiveSession?.accessToken) {
            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + "user/current", {
                method: 'PUT',
                body: JSON.stringify({ "preferred_language": lang }),
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + effectiveSession.accessToken,
                },
            })
                .then(response => response.json())
                .then(data => {
                    if (data.message !== undefined) {
                        Sentry.captureException("user/current: " + data.message + " : " + getCookie("userMail"));
                    }
                    if (data.message === undefined) {
                        Object.keys(allLanguages).forEach(key => {
                            if (allLanguages[key].uuid === lang) {
                                console.log("selected lang", allLanguages[key]);
                                setPreferredLanguage(lang);
                                setLanguage(allLanguages[key].code);
                                // případně aktualizuj i další jazykový stav
                            }
                        });
                    }
                });
        }
    };

    // --- Načtení aktuální session pomocí getSession při mountu
    useEffect(() => {
        getSession().then(sess => {
            if (sess) {
                setCurrentSession(sess);
                // loadTeamInfoAPI();
                // refreshAuthInfo(sess);
                // loadLanguages();
                // loadCurrencies();
            }
        });
    }, []);

    // --- Další useEffect, pokud by se změnila session (fallback)
    useEffect(() => {
        if (effectiveSession) {
            loadTeamInfoAPI();
            refreshAuthInfo(effectiveSession);
            loadLanguages();
            loadCurrencies();
        }
    }, [effectiveSession]);

    // --- useEffect pro inicializaci country, timezone, měny a jazyka, pokud chybí
    useEffect(() => {
        if (authData.needToInitCountry) {
            fetch('https://ipapi.co/json/')
                .then(response => response.json())
                .then(data => {

                    setCountryFunction(data.country_code);
                })
                .catch(error => {
                    Sentry.captureException('Error fetching country: ' + getCookie('userMail'));
                    console.error("Error fetching country:", error);
                });
        }
    }, [authData.needToInitCountry]);

    useEffect(() => {
        if (authData.needToInitTimezone) {
            fetch('https://ipapi.co/json/')
                .then(response => response.json())
                .then(data => {

                    setTimezoneFunction(data.timezone);
                })
                .catch(error => {
                    Sentry.captureException('Error fetching timezone: ' + getCookie('userMail'));
                    console.error("Error fetching timezone:", error);
                });
        }
    }, [authData.needToInitTimezone]);

    useEffect(() => {
        if (Object.keys(allCurrencies).length > 0 && authData.needToInitCountry) {
            if (getBrowserLocale() === "cs") {
                Object.keys(allCurrencies).forEach(key => {
                    if (allCurrencies[key].code === "czk") {
                        setCurrencyFunction(allCurrencies[key].uuid);
                    }
                });
            } else {
                Object.keys(allCurrencies).forEach(key => {
                    if (allCurrencies[key].code === "eur") {
                        setCurrencyFunction(allCurrencies[key].uuid);
                    }
                });
            }
        }
    }, [authData.needToInitCountry, allCurrencies]);

    useEffect(() => {
        if (Object.keys(allLanguages).length > 0 && authData.needToInitLang) {
            console.log("NEED TO INIT LANG");
            if (getBrowserLocale() === "cs") {
                Object.keys(allLanguages).forEach(key => {
                    if (allLanguages[key].code === "cs") {
                        setLanguageFunction(allLanguages[key].uuid);
                    }
                });
            } else {
                Object.keys(allLanguages).forEach(key => {
                    if (allLanguages[key].code === "en") {
                        setLanguageFunction(allLanguages[key].uuid);
                    }
                });
            }
        }
    }, [authData.needToInitLang, allLanguages]);

    return (
        <AppContext.Provider value={{
            authData,
            setAuthData,
            refreshAuthInfo,
            loadTeamInfoAPI,
            loadCurrencies,
            loadLanguages,
            setCountryFunction,
            setTimezoneFunction,
            setCurrencyFunction,
            setLanguageFunction,
            allCurrencies,
            allLanguages
        }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};
